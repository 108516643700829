import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Horizon from "../components/horizon"

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Footer_ from '../components/footer_'
import ArticleHorizon from "../components/horizon-articles"

class Index extends React.Component {
  render() {
    const hero = this.props.data.allContentfulHero.edges[0].node
    const info = documentToReactComponents(hero.childContentfulHeroInfoRichTextNode.json)

    return (
      <Layout>    
        <Hero title={hero.title} subtitle={hero.subtitle} right={info}/>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Horizon title="projects" items={this.props.data.allContentfulProject}></Horizon>
        <ArticleHorizon title="thoughts" items={this.props.data.allContentfulArticle}></ArticleHorizon>
        <Footer_ content={info}></Footer_>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
{
  allContentfulHero {
    edges {
      node {
        title
        subtitle
        childContentfulHeroInfoRichTextNode {
          json
        }
      }
    }
  }
  allContentfulProject {
    edges {
      node {
        title
        infoTags
        thumbnail{
          fluid(maxWidth:1000){
            src
          }
        }
      }       
    }
  }
  allContentfulArticle {
    edges {
      node {
        title
        date
        previewText {
          internal {
            content
          }        
        }
        content {
          json
        }
      }
    }
  }
}
`