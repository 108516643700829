import React from "react"
import "./css/footer.css"
import Link from 'gatsby-plugin-transition-link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

class Footer_ extends React.Component {
    render() {    
        return (
        <div className="footer-wrapper">   
            <div className="content-left">
                <span className="title">ABOUT</span>
                { this.props.content }
            </div>        
            <div className="content-right">
                <p>                
                    <Link className="legal" to="/legal">
                        LEGAL & PRIVACY
                    </Link>
                </p>
            </div>        
        </div>
        )
    }
}

export default Footer_