import React from "react"
import "./css/horizon.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Link from "gatsby-plugin-transition-link"

class Horizon extends React.Component {
    render() {
        const data = this.props.items.edges

        const items = data.map((item) =>
            <AniLink cover bg="#f1f1f1" to={"/" + item.node.title.replace(/ /g, '-')} duration={1} style={{backgroundImage: "url(" + item.node.thumbnail.fluid.src + ")"}} key={item.node.title}>
                <div className="item">
                    <div className="content">
                        <span className="topics">
                            {item.node.infoTags}
                        </span>
                        <span className="title">
                            {item.node.title}
                        </span>
                    </div>
                </div>
            </AniLink>
        )
            
        return (
            <div className="horizon-wrapper">
                <div className="horizon-title">
                    <div className="title">
                        { this.props.title }
                    </div>
                    <div className="icon">
                    </div>
                </div>
                <div className="horizon">
                    {items}
                </div>
            </div>            
        )
    }
}

export default Horizon