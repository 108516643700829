import React from "react"
import "./css/hero.css"
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

class Hero extends React.Component {
render() {
return (
<div className="hero-wrapper">
    <div className="hero">
        <div className="content left">
            <h1>
                <Link to="/">
                { this.props.title }
                </Link>
            </h1>
            <h2>
                { this.props.subtitle }
            </h2>
        </div>
        <div className="content right">
            { this.props.right }
        </div>
    </div>
</div>
)
}
}

export default Hero