import React from "react"
import "./css/horizon.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link'

class ArticleHorizon extends React.Component {
    render() {
        const data = this.props.items.edges   
             
        const items = data.map((item) => 
            <AniLink cover bg="#f1f1f1" to={"/" + item.node.title.replace(/ /g, '-')} duration={1} key={item.node.title}>
                <div className="item">
                    <div className="content">
                        <h1>
                            {item.node.title}
                        </h1>
                        <p>
                            {item.node.previewText.internal.content}
                        </p>
                    </div>                
                </div>
            </AniLink>
        )
            
        return (
            <div className="horizon-wrapper">
                <div className="horizon-title">
                    <div className="title">
                        { this.props.title }
                    </div>
                    <div className="icon">
                    </div>
                </div>
                <div className="horizon-article">
                    {items}
                </div>
            </div>            
        )
    }
}

export default ArticleHorizon